<template>
	<div class="mainTem">
		<!-- URL -->
		<!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'UserManage' }">{{$t('i18nn_55793d80c64c2dc7')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('i18nn_fcd37bf760bc15c3')}}</el-breadcrumb-item>
    </el-breadcrumb> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="4" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>{{$t('i18nn_859fd2c643fde5a3')}}</el-breadcrumb-item>
					</el-breadcrumb>
				</el-col>
				<el-col :span="20" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
					<el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button> -->
				</el-col>
			</el-row>
		</div>
		<!-- 主界面 -->
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">

			<div class="tableConTable">


				<el-table :height="$store.state.tableMaxHeight" :data="tableData" stripe border style="width: 100%"
					row-key="id" :tree-props="{children: 'subListMenuList', hasChildren: 'hasChildren'}">

					<el-table-column label="ID" prop="id" width="230"></el-table-column>		
					<el-table-column :label="$t('i18nn_fe054fb92fbd6690')">
						<template slot-scope="scope">
							<div v-if="scope.row.parentId === '0'">
								<label :class="'el-icon-menu'">&nbsp;{{scope.row.menuName}}</label>
							</div>
							<div v-else>
								<label :class="'el-icon-tickets'">&nbsp;{{scope.row.menuName}}</label>
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('i18nn_7803ff939c276311')">
						<template slot-scope="scope">
							<div v-if="scope.row.parentId === '0'">
								<label :class="'el-icon-menu'">&nbsp;{{scope.row.menuNameEn}}</label>
							</div>
							<div v-else>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<label :class="'el-icon-tickets'">&nbsp;{{scope.row.menuNameEn}}</label>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="KEY" prop="url"></el-table-column>

					<el-table-column label="ICON" prop="iconcls">
						<template slot-scope="scope">
							<div>
								<label :class="scope.row.iconcls">&nbsp;{{scope.row.iconcls}}</label>
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('i18nn_893f64784c70bd2a')" prop="isShow">
						<template slot-scope="scope">
							<el-tag v-if="'1'===scope.row.isShow">{{$t('i18nn_d79e2ed6a2fc66fd')}}</el-tag>
							<el-tag v-else-if="'0'===scope.row.isShow" type="info">{{$t('i18nn_b978a8259ecfca8f')}}</el-tag>
							<el-tag v-else type="info">{{scope.row.isShow}}</el-tag>
						</template>
					</el-table-column>

					<el-table-column :label="$t('i18nn_dbcffdf31e86d2d8')" prop="sortNo">

					</el-table-column>

					<el-table-column :label="$t('93f5ca01b006206c')" width="200px" fixed="right">
						<template slot-scope="scope">
							<el-button type="warning" size="mini" icon="el-icon-edit" @click="openDioalog(scope.row)">{{$t('6267f3aedf895209')}}</el-button>
							<el-button type="danger" size="mini" icon="el-icon-minus" @click="delPageAction(scope.row)">{{$t('e33c9b93c36fd250')}}</el-button>
						</template>
					</el-table-column>
				</el-table>

			</div>
		</div>
		<!-- 弹窗 -->
		<el-dialog :title="$t('i18nn_ba14fba14998cbd6')" :close-on-click-modal="false" :visible.sync="dialogFormVisible"
			width="800px">
			<el-form ref="form" :rules="formRules" :model="form" label-width="120px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<el-form-item label="上级菜单：" prop="parentId">
					<el-select filterable clearable :disabled="form.id && form.subListMenuList && form.subListMenuList.length>0" v-model="form.parentId" :placeholder="$t('i18nn_f241d2c8737ad7ba')"
						@change="selChange($event)">
						<el-option v-for="item in dialogTableDate" :key="item.id" :label="item.menuName"
							:value="item.id">
							<div v-if="item.id === '0'">
								<i class="el-icon-s-home"></i>{{item.menuName}}
							</div>
							<div v-else style="padding-left: 20px;">
								<label class="el-icon-menu">{{item.menuName}}</label>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('i18nn_ea4756bc1642e0f1')" prop="menuName">
					<el-input type="text" clearable v-model="form.menuName" :placeholder="$t('5a9aefbc03c778f7')"></el-input>
				</el-form-item>
				<el-form-item label="名称(英文)" prop="menuNameEn">
					<el-input type="text" clearable v-model="form.menuNameEn" :placeholder="$t('5a9aefbc03c778f7')"></el-input>
				</el-form-item>
				<el-form-item label="KEY" prop="url">
					<el-input type="text" clearable v-model="form.url" :placeholder="$t('5a9aefbc03c778f7')"></el-input>
				</el-form-item>
				<el-form-item label="ICON" prop="iconcls">
					<el-input type="text" clearable v-model="form.iconcls" :placeholder="$t('5a9aefbc03c778f7')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_a5c7073db104f92e')">
					<el-switch v-model="form.isShow" active-value="1" inactive-value="0" :active-text="$t('i18nn_d79e2ed6a2fc66fd')"
						:inactive-text="$t('i18nn_b978a8259ecfca8f')">
					</el-switch>
				</el-form-item>
				<el-form-item label="排序：" prop="sortNo">
					<el-input-number v-model="form.sortNo" :precision="0" :min="0"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-管理后台-系统管理-菜单管理'
		// },
		data() {
			return {
				// 要展开的行，数值的元素是row的key值
				// expands: [],

				// 表单信息
				tableData: [],
				loading_load: false,
				// 弹窗信息
				dialogTableDate: [],
				dialogFormVisible: false,
				loading: false,
				form: {
					"id": null,
					"menuName": "", //菜单名称
					"menuNameEn": "", //"英文名",
					"url": "", //URL
					"iconcls": "el-icon-document",
					"parentId": "0", // 上级菜单 上级,无上级为0",
					"parentName": "", // 上级菜单
					"isShow": '1',
					"sortNo": "1",
					// "leaf": 0,
					// "parentLeaf": 0
				},
				formRules: {
					parentId: [{
						required: true,
						message: this.$t('2ad108ab2c560530'),
						trigger: 'change'
					}, ],
					menuName: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}, ],
					menuNameEn: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}, ],
					iconcls: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}, ],
					url: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}, ],
					isShow: [{
						required: true,
						message: this.$t('2ad108ab2c560530'),
						trigger: 'change'
					}, ],
					sortNo: [{
							required: true,
							message: this.$t('5a9aefbc03c778f7'),
							trigger: 'change'
						},
						// { required: true, message: this.$t('i18nn_61e631acdbe6900b'), trigger: 'blur' },
						// { pattern: this.$Validate.RegNumber,message: this.$t('057d4e3f1ee2d9bc')},
						// { pattern: this.$Validate.RegInteger,message: this.$t('i18nn_5d2e2702224d280d')}
					],
					// ],
					// parentName: [
					//   { required: true, message: this.$t('i18nn_f241d2c8737ad7ba'), trigger: 'change' },
					// ],
				}
			}
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {},
		methods: {
			// 初始化数据
			initData() {
				this.tableData = [];
				this.getPageData();
			},
			initFormDate() {
				this.form = {
					// "menuId": null,
					// "menuName": "", //菜单名称
					// "request": "", //URL
					// "parentId": "0", // 上级菜单
					// "parentName": "", // 上级菜单
					// "isShow":true,//是否隐藏
					// "sortNo":"1",//排序
					// "leaf": 0,
					// "parentLeaf": 0,

					"id": null,
					"menuName": "", //菜单名称
					"menuNameEn": "", //"英文名",
					"url": "", //URL
					"iconcls": "el-icon-document",
					"parentId": "0", // 上级菜单 上级,无上级为0",
					"parentName": "", // 上级菜单
					"isShow": "1",
					"sortNo": "1",

				}
			},
			// 查询菜单集合
			getPageData() {
				this.loading_load = true;
				this.$http.get(this.$urlConfig.WhClientMenuAll, {})
					.then(({
						data
					}) => {
						console.log("菜单列表，请求成功");
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.rows != null && data.rows.length > 0) {
							let tableData = data.rows;
							// let WhTableData = tableData.filter(item=>{
							// 	return item.request && item.request.indexOf('WarehouseCenter')>-1
							// });
							// WhTableData.map(item=>{
							//   item.children = item.menuDtoList;
							// });
							this.tableData = tableData;
							// this.$message.success('菜单列表数据处理成功');
							// console.log(newMuen);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch((error) => {
						console.log(error);
						console.log("菜单列表，请求失败");
						this.$message.error('列表数据，请求失败！');

						this.loading_load = false;
					});
			},
			//打开弹窗
			openDioalog(openData) {
				console.log('openData', openData);
				this.dialogTableDate = this.tableData.concat([]);
				// this.dialogTableDate.unshift({
				//      "menuId": "0",
				//      "menuName": this.$t('i18nn_4af676d69de62f2b'),
				//      "request": "#",
				//      "parentId": "0",
				//      "leaf": 0,
				//      "parentLeaf": 0,
				//      "isShow":true,//是否隐藏
				//      "sortNo":"1",//排序
				//    });

				this.dialogTableDate.unshift({
					"id": "0",
					"menuName": "HYTX",
					"menuNameEn": "HYTX", //"英文名",
					"url": "#",
					"parentId": "0",
					"iconcls": "el-icon-document",
					// "leaf": 0,
					// "parentLeaf": 0,
					"isShow": "1", //是否隐藏
					"sortNo": "1", //排序
				});

				// "id": null,
				// "menuName": "", //菜单名称
				// "menuNameEn":"", //"英文名",
				// "url": "", //URL
				// "iconcls":"",
				// "parentId": "0", // 上级菜单 上级,无上级为0",
				// "parentName": "", // 上级菜单
				// "isShow":true,
				// "sortNo":"1",
				// this.dialogTableDate[0] = {
				//   "menuId": "0",
				//   "menuName": this.$t('i18nn_4af676d69de62f2b'),
				//   "request": "#",
				//   "parentId": "0",
				//   "leaf": 0,
				//   "parentLeaf": 0,
				//   "isShow":true,//是否隐藏
				//   "sortNo":"1",//排序
				// };
				// if (this.tableData != null) {
				//   for (let i = 1; i <= this.tableData.length; i++) {
				//     this.dialogTableDate[i] = this.tableData[i - 1];
				//   }
				// }
				this.dialogFormVisible = true;
				// 新增
				if (null == openData) {
					this.resetForm('form');
					this.initFormDate();
				}
				// 编辑
				else {
					this.resetForm('form');

					this.form = Object.assign({}, openData);
					// this.form.isShow = '1'==this.form.isShow?false:true;
					this.selChange(this.form.parentId);
				}
			},
			//下拉框选择改变
			selChange(vId) {
				// if (vId == null) {
				//   return;
				// }
				// for (let i = 0; i < this.dialogTableDate.length; i++) {
				//   if (this.dialogTableDate[i].id == vId) {
				//     this.form.parentLeaf = this.dialogTableDate[i].leaf;
				//     break;
				//   }
				// }
			},
			// 提交信息
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						// 最多只能添加二级菜单
						// formData.leaf = parseInt(formData.parentLeaf) + 1;
						// if (formData.leaf > 2) {
						//   this.$alert('只能添加一级菜单和二级菜单，请重新选择上级菜单！', this.$t('cc62f4bf31d661e3'), {
						//     type: 'warning',
						//     confirmButtonText: this.$t('204ffab8a6e01870'),
						//   });
						//   return;
						// }
						//顶层菜单必须含有‘WarehouseCenter’
						// if(formData.parentId == '0' && formData.request.indexOf('WarehouseCenter')<=-1){
						// 	this.$alert(`顶级菜单URL必须含'WarehouseCenter'字符串，便于区分是否是仓库菜单!`, this.$t('cc62f4bf31d661e3'), {
						// 	  type: 'warning',
						// 	  // confirmButtonText: this.$t('204ffab8a6e01870'),
						// 	});
						// 	return;
						// }


						// formData.expand = 0;
						// formData.isShow = formData.isShow?'0':'1';
						// formData.id = formData.id;

						console.log(formData);
						// return;
						if (formData.menuId == null) {
							this.postData(this.$urlConfig.WhClientMenuAdd, [formData]);
						} else {
							this.postData(this.$urlConfig.WhClientMenuEdit, formData);
						}
					} else {
						console.log('error submit!!');
						this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							// confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//提交信息
			postData(url, formData) {
				this.loading = true;
				this.$http.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loading = false;
						if (200 == data.code) {
							this.dialogFormVisible = false;
							this.getPageData();
							// this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
							//   // type: 'success',
							//   // confirmButtonText: this.$t('204ffab8a6e01870'),
							// });
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
						} else {
							if (!data.msg) {
								data.msg = "提交失败,请重试";
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								// confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
						// this.getPageData();
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							// confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},
			// 重置输入框
			resetForm(formName) {
				console.log(formName);
				this[formName] = {};
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			}, //删除操作
			delPageAction(dataParm) {
				if (dataParm.subListMenuList != null && dataParm.subListMenuList.length > 0) {
					this.$alert('请先删除下级菜单！', this.$t('cc62f4bf31d661e3'), {
						type: 'warning',
						// confirmButtonText: this.$t('204ffab8a6e01870'),
					});
					return;
				}

				this.$confirm(this.$t('i18nn_18a47a2171c01d1c') + dataParm.menuName + '吗?', this.$t('daaaeb1b7b22b126'), {
					// confirmButtonText: this.$t('204ffab8a6e01870'),
					// cancelButtonText: this.$t('4b3340f7872b411f'),
					type: 'warning'
				}).then(() => {
					this.delPageData(dataParm);
				}).catch(() => {});
			},
			//删除特定数据
			delPageData(dataParm) {
				console.log(dataParm);
				this.loading_load = true;
				this.$http.delete(this.$urlConfig.WhClientMenuDel+'/'+dataParm.id, {
						// id: dataParm.menuId
					})
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							// this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
							//   // type: 'success',
							//   // confirmButtonText: this.$t('204ffab8a6e01870'),
							// });
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
							this.getPageData();
						} else {
							if (!data.msg) {
								data.msg = "删除失败,请重试";
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								// confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
						// this.getPageData();
					})
					.catch((error) => {
						console.log(error);
						this.loading_load = false;
						this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							// confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},
		}
	}
</script>
<!-- Add "scoped" attribute to limit leaf to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>
